import { Link } from "gatsby"
import React from "react"
import MarkdownView from "react-showdown"
import ArticleCard from "../components/articleCard"
import Image from "../components/image"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"

// import { graphql } from 'gatsby';

// export const query = graphql`
//   query Category($name: String!) {
//     category: strapiCategory(name: { eq: $name }) {
//       name
//       providers {
//         id
//         name
//       }
//     }
//   }
// `;

const Category = ({ pageContext }) => {
  const category = pageContext.category
  const providers = category.providers.slice(0, 3)
  const products = category.products
  const firstArticle =
    category.articles.length > 0 ? category.articles[0] : null
  const articlesPartTwo = category.articles.slice(1, 4)

  return (
    <Layout>
      <section>
        <div className="box">
          <h2>{category.shortDescription}</h2>
          <p>{category.description}</p>
        </div>

        {/* first featured article */}
        <div className="row gtr-uniform gtr-200">
          {firstArticle && (
            <>
              <div className="col-6 col-12-small">
                <Link to={`/${firstArticle.slug}`} className="image fit">
                  <Image image={firstArticle.cover.image} />
                </Link>
              </div>
              <div className="col-6 col-12-small">
                <h3>{firstArticle.title}</h3>
                <MarkdownView
                  markdown={`${firstArticle.cover.content.substring(
                    0,
                    980
                  )}... [read more](/${firstArticle.slug})`}
                />
              </div>
            </>
          )}

          {/* row with articles 2 to 4 */}
          {articlesPartTwo.map(article => (
            <ArticleCard article={article} />
          ))}
        </div>

        {/* PRODUCTS */}
        <hr className="major" />

        <h2>Find a {category.wordForProduct || `Product`}</h2>
        {/* TODO: Make the search real */}
        <div className="row">
          <div className="col-4 col-12-small">
            <div className="box">
              <div className="row gtr-uniform">
                <div className="col-12 col-12-xsmall">
                  <input
                    type="text"
                    name="product-name"
                    id="product-name"
                    placeholder="Name"
                  />
                </div>

                <div className="col-12">
                  <input
                    type="text"
                    name="product-zip"
                    id="product-zip"
                    placeholder="Zip"
                  />
                </div>

                <div className="col-12">
                  <a className="button primary fit">Search</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-8 col-12-small">
            {/* TODO: Turn this into a component!! */}
            <div className="row gtr-uniform">
              {products.map(product => (
                <div key={product.slug}>
                  <div className="col-9">
                    <h4>{product.name}</h4>
                    <span className="image left">
                      <img src="https://picsum.photos/seed/prod/200" alt="" />
                    </span>
                    {product.description}
                  </div>
                  <div
                    className="col-3 align-center"
                    style={{ marginTop: "3em" }}
                  >
                    <div className="col-6 col-12-small">
                      <ul className="actions stacked">
                        <li>
                          <a className="button small">See Products</a>
                        </li>
                        <li>
                          <a>Read Review</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* PROVIDERS */}
        <hr className="major" />

        <h2>Find a {category.wordForProvider || `Provider`}</h2>
        {/* TODO: Make the search real */}
        <div className="row">
          <div className="col-4 col-12-small">
            <div className="box">
              <div className="row gtr-uniform">
                <div className="col-12 col-12-xsmall">
                  <input
                    type="text"
                    name="provider-name"
                    id="provider-name"
                    placeholder="Name"
                  />
                </div>

                <div className="col-12">
                  <input
                    type="text"
                    name="provider-zip"
                    id="provider-zip"
                    placeholder="Zip"
                  />
                </div>

                <div className="col-12">
                  <a className="button primary fit">Search</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-8 col-12-small">
            {/* TODO: Turn this into a component!! */}
            <div className="row gtr-uniform">
              {providers.map(provider => (
                <div key={provider.slug}>
                  <div className="col-9">
                    <h4>{provider.name}</h4>
                    <span className="image left">
                      <img
                        src="https://picsum.photos/seed/prov/200/200"
                        alt=""
                      />
                    </span>
                    {provider.description}
                  </div>
                  <div
                    className="col-3 align-center"
                    style={{ marginTop: "3em" }}
                  >
                    <div className="col-6 col-12-small">
                      <ul className="actions stacked">
                        <li>
                          <a className="button small">See Products</a>
                        </li>
                        <li>
                          <a>Read Review</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* NEWSLETTER */}
        {category.hasNewsletter && (
          <>
            <hr className="major" />
            <Newsletter
              title={category.newsletterTitle}
              description={category.newsletterDescription}
            />
          </>
        )}
      </section>
    </Layout>
  )
}

export default Category
